<template>
  <div
    class="video-player"
    v-if="livestreamAvailable"
  >
      <div class="video-player-container">
        <Vimeo
          class="video"
          :currentUser="currentUser"
          :code="livestream.code[locale]"
          :chatActive="chatActive"
          :isEvent="livestreamType == 'vimeoEvent' ? true : false" 
          v-if="livestreamType === 'vimeoEvent' || livestreamType === 'vimeoPlayer'"
        />
        <Zoom class="video" v-if="livestreamType === 'zoom'" />
        <Youtube
          class="video"
          :chatActive="chatActive"
          :code="livestream.code[locale]"
          :livestream="livestream"
          v-if="livestreamType === 'youtube'"
        />
        <Whereby
          class="video"
          :chatActive="chatActive"
          :code="livestreamCode"
          :livestream="livestream"
          v-if="livestreamType === 'whereby'"
        />
        <Jitsi
          class="video"
          :chatActive="chatActive"
          :code="livestreamCode"
          :jitsi="livestream.jitsi"
          v-if="livestreamType === 'jitsi'"
        />
      
    <b-overlay no-wrap  :show="!livestream.active">
<template #overlay>
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label">{{ livestream.inactiveText[locale] }}</p>
          <!-- <b-button
            ref="cancel"
            variant="outline-danger"
            size="sm"
            aria-describedby="cancel-label"
            @click="show = false"
          >
            Cancel
          </b-button> -->
        </div>
      </template>
    </b-overlay>
      </div>
  </div>
</template>
<script>
import Zoom from "@/components/video/Zoom.vue";
import Youtube from "@/components/video/Youtube.vue";
import Vimeo from "@/components/video/Vimeo.vue";
import Whereby from "@/components/interactive-livestream/Whereby.vue";

export default {
  props: ["livestream"],
  name: "Home",
  components: {
    Vimeo,
    Zoom,
    Youtube,
    Whereby,
  },
  computed: {
    livestreamAvailable() {
      return this.livestream?.id;
    },
    settings() {
      return this.$store.state.Config.all;
    },
    locale() {
      return this.$root.$i18n.locale;
    },
    chatActive() {
      return this.livestream?.chat?.active;
    },
    livestreamType() {
      return this.livestream?.type[this.locale];
    },
    livestreamCode() {
      return this.livestream?.code[this.locale];
    },
    currentUser() {
      return this.$store.getters["Auth/currentUser"];
    },
  },
};
</script>
<style lang="scss" scoped>
.video-player {
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 0px;
  margin-right: 0px;
}

.video-player-container {
  padding-left: 0px !important;
  padding-right: 0px;
  padding-top: 56.25%;
  width: 100%;
  height: auto;

  .video {
    padding-left: 0px !important;
    padding-right: 0px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 999px) {
  .video-player-container {
    width: 100%;
    padding-top: 75%;
  }
}
</style>
